import React, {useEffect} from 'react'
import VisionCarousel from '../components/Vision Carousel';
import MissionCarousel from '../components/Mission Carousel';
// import ValuesAccordion from '../components/ValuesAccordion';
import CoreValues from '../components/CoreValues';
const WhoWeAre = () => {


  return (
    <>

     
    <section className='section-cover who-we-are-cover' >
      <h2 className="home-h2 wwa-h2">Who We Are</h2>

      
          <div className='content-container vm-container'>
                 <div className='vision-mission-content'>
                    <article className='vision-mission-slides'>
                          <VisionCarousel></VisionCarousel>

                    </article>

                    <article className='vision-mission-info'>
                          <h3 className='title-h3'>Our Vision</h3>
                          <div className='vm-line'></div>
                          <p>To be one of the leading coffee processors and exporters in the Great Lakes Region</p>
                    <h3 className='title-h3'>Our Mission</h3>
                          <div className='vm-line'></div>
                          <p>To provide timely processed quality coffee products to the market </p>
                    
                    </article>

                    


                 </div>

                 {/* <div className='vision-mission-content mission-info'>

                  <article className='vision-mission-slides'>
                         
                       <MissionCarousel></MissionCarousel>
                    </article>

                    <article className='vision-mission-info '>
<h3 className='title-h3'>Core Values</h3>
<div className='vm-line'></div>
<p className='values-intro'>In our quest to become a leading consulting engineering company, our business is driven by the core principles outlined below, which we believe will be the main contributors to the success of our practice:</p>
           <CoreValues></CoreValues>

                    </article>


                 </div> */}
          </div>
    </section>

    <section className='cv-cover' style={{ backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.863), rgba(0, 0, 0, 0.863)), url("./images/1.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
      <div>
         <h3 className='title-h3 title-h3a'>Core Values</h3>
         <ul>
          <li>Integrity</li>
<li>Honesty</li>
<li>Discipline</li>
<li>Teamwork</li>
<li>Quantity, quality & timely delivery</li>

         </ul>

      </div>

    </section>
    

    </>
  )
}

export default WhoWeAre