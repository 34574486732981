import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel'

const Services = () => {

useEffect(()=>{
document.title = 'Municipal Infrastructure | Design Allied World Engineering Ltd';

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
              

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Municipal Infrastructure</h3>
                     
                     <ul>
                  <li>Gravel and surfaced roads.</li>
 <li>Pavement management systems for gravel and surfaced roads.</li>
<li>Stormwater drainage inclusive of open and piped drainage systems and Stormwater detention systems.</li>
<li>Subsoil drainage systems.</li>
<li>Sewerage reticulation design.</li>
<li>Water reticulation design.</li>
<li>Water demand and water loss management systems.</li>
<li>Engineering services master planning for structured human settlements.</li>
<li>Planning, design, tender documentation, construction supervision and contract administration for conventional and labour-intensive contracts for municipal infrastructure.</li>
                     </ul>
                  </article>

                  <article className='service-slides'>
                    <PowerCarousel></PowerCarousel>
                 
                  </article>     
            </div>

           

            
           </div>

    </section>
  )
}

export default Services