import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel'

const Services = () => {

useEffect(()=>{
document.title = 'Water & Sanitation | Design Allied World Engineering Ltd';

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
              

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Water & Sanitation</h3>
                       
                       <ul>
                    <li>Water resource planning.</li>
 <li>Master planning of water bulk and distribution systems for rural and urban water supply schemes.</li>
 <li>Hydrology studies of water catchment areas.</li>
 <li>Interpretation of geohydrological studies.</li>
 <li>Water source development inclusive of abstraction infrastructure.</li>
 <li>Bulk water supply schemes including pipelines, pump-stations, reservoirs and water towers.</li>
 <li>Regional and local water supply schemes.</li>	
 <li>Water demand and water loss management systems.</li>
 <li>Master planning of sewerage disposal systems.</li>
 <li>Sewerage conveyance systems inclusive of outfall and interceptor sewers.</li>
 <li>Sewer pump stations and rising mains.</li>
 <li>On-site sanitation systems.</li>
 <li>Water and wastewater treatment.</li>
 <li>Planning, design, tender documentation, construction supervision and contract administration forconventional and labour-based contracts for water and sanitation services.</li>

                     
                     </ul>

                  </article>

                  <article className='service-slides'>
                    <RoadCarousel></RoadCarousel>
                 
                  </article>     
            </div>

           
           </div>

    </section>
  )
}

export default Services