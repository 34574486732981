import React, {useEffect} from 'react';



const Services = () => {



  return (
    <section className='section-cover services-cover products-cover' >
      <h2 className="home-h2 services-h2">Our Products</h2>
      {/* <div className='content-container content-container1 content-container2'>
                

        </div> */}
           <div className='content-container services-container services-container1'>
              <div className='service-container service-container1 service-container-first'>
                  <article className='service-title '>
                    <h3 className='title-h3 service-h3'>Robusta Coffee</h3>
                     <div className='underline undeline1'></div>
                     <p>Robusta coffee is known for its strong, earthy flavor and its acidity. These coffee beans contain more acid and caffeine content than any Arabica. They are used as caffeine boosters as well as fillers to improve the taste of flat or weak tasting coffee. When Robusta coffee is sorted and processed with the same amount of care given to Arabica coffee, it can attain better refinement to yield a milder flavor than usual.</p>
                      
                  </article>

                  <article className='service-slides '>
                    {/* <TelecomCarousel></TelecomCarousel> */}
                    <img src='images/24.jpg' alt='coffee image' className='coffee-photo'></img>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container service-container1'>
                  <article className='service-title '>
                    <h3 className='title-h3 service-h3'> Arabica Coffee</h3>
                    <div className='underline undeline1'></div>
                    <p>Arabica coffee exhibits a variety of flavors and characteristics; region, soil, altitude, temperature are some of the factors that affect the quality of the resulting coffee bean. Arabica coffee is cultivated at lower altitudes, it matures much faster and requires the protection of shade trees to slow the growth. Delicate and susceptible to a variety of pests, diseases, as well as weather conditions like drought and frost, Arabica coffee trees require a lot of care and attention in order to attain their full potential. </p>
                    {/* <ul>
                      <li>Natural Ugandan Robusta Coffee.</li>
                      <li>Natural Ugandan Arabica Coffee.</li>
                      <li>Washed Ugandan Arabica Coffee.</li>
                     </ul> */}
                  </article>

                  <article className='service-slides '>
                    {/* <PowerCarousel></PowerCarousel> */}
                  <img src='images/25.jpg' alt='coffee image' className='coffee-photo'></img>
                  </article>     
            </div>

           </div>

    </section>
  )
}

export default Services