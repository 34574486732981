import React from 'react';
import {Link} from 'react-router-dom'
import {FaChevronRight, FaFacebookSquare, FaTwitterSquare, FaLinkedin} from 'react-icons/fa';


let scrollFunc = () =>{
window.scrollTo({
   top:0,
behavior: "smooth",
});

}



const Footer = () => {

  return (
    <section className='section-cover footer-cover' style={{ backgroundImage: `linear-gradient( rgba(114, 49, 33, 0.799), rgba(0, 0, 0, 0.863)), url("./images/8.jpg")`,backgroundAttachment:'fixed',backgroundSize:'cover', backgroundPosition:'center'
    }}>
      <div className='footer-content'>

 



             <div className='footer-inner-content'>
                    <h3>Quick Links</h3>
                    <div className="footer-links-cover">
                        <div className='footer-link'>
                             <Link to='/' onClick={scrollFunc}>  <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Home</p></Link> 
                        </div>

                         <div className='footer-link'>
                              <Link to='about-us' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>About Us</p></Link> 
                        </div>

                        {/* <div className='footer-link'>
                              <Link to='our-team' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Team</p></Link> 
                        </div> */}

                        <div className='footer-link'>
                            <Link to='products' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Products</p></Link> 
                        </div>

                          <div className='footer-link'>
                                <Link to='services' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Services</p></Link>
                        </div>

                      
                          {/* <div className='footer-link'>
                              <Link to='clients' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Our Clients</p></Link> 
                        </div> */}

                       

                         
                      
                      
                    </div>

             </div>

            

             {/* <div className='footer-inner-content'>
                    <h3>Our Services</h3>
                    <div className="footer-links-cover">
                      

                         <div className='footer-link'>
                              <Link to='roads-highways' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Major Roads & Highways</p></Link> 
                        </div>

                        <div className='footer-link'>
                              <Link to='municipal-infrastructure' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Municipal Infrastructure</p></Link> 
                        </div>

                          <div className='footer-link'>
                                <Link to='water-sanitation' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Water & Sanitation</p></Link>
                        </div>

                        <div className='footer-link'>
                              <Link to='structural-engineering' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Structural Engineering</p></Link> 
                        </div>
                          <div className='footer-link'>
                              <Link to='project-management' onClick={scrollFunc}> <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Project Management</p></Link> 
                        </div>

                        <div className='footer-link'>
                            <Link to='green-building-projects' onClick={scrollFunc}>   <p><FaChevronRight></FaChevronRight></p> <p className='footer-a'>Green Building Projects</p></Link> 
                        </div>

                    </div>

             </div> */}

              <div className='footer-inner-content'>
<div className='footer-location'>
                      <h3>Location</h3>
                      <p>Plot 133, Musigula Street, Nalukolongo
Off Kampala – Masaka Road<br></br>
P. O. Box 36533, Kampala - Uganda
</p>

                    </div>

                    <div className='footer-contacts'>
                      <h3>Call Us On</h3>
                      <p>
                        {/* +256772 702493<br></br> */}
                        +256701 702493
                      </p>

                    </div>

                     

             </div>

             <div className='footer-inner-content'>
                <div className='footer-email'>
                      <h3>Write To Us At</h3>
                   <Link to='contact' onClick={scrollFunc} className='email-link'>  <p>info@karazcoffeefactory.com<br></br>karazcoffeefactory@gmail.com</p></Link> 
                      
                      </div>
                      <div className='footer-social-icons'>
                         <h3>Connect With Us On</h3>
                         <div className='footer-icons'>
                                 <span>
<FaFacebookSquare></FaFacebookSquare>
                                 </span>
                                 <span>
<FaTwitterSquare></FaTwitterSquare>
                                 </span>

                                 <span>
<FaLinkedin></FaLinkedin>
                                 </span>  
                         </div>
                        
                        
                      </div>

             </div>

        
        
        
        </div>
        <div className='footer-bottom'>
            <p>    &copy; {new Date().getFullYear()} <span className="date"></span> Karaz Coffee Factory Ltd | Website Developed By <a href="https://bensitesug.com" target="_blank" className="footer-link-a" rel="noopener noreferrer">BenSites</a></p>
          </div>
    </section>
  )
}

export default Footer