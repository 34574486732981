import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';


const Services = () => {



  return (
    <section className='section-cover services-cover' >
      <h2 className="home-h2 services-h2">Our Services</h2>
      <div className='content-container content-container1 content-container2'>
                 {/* <p className='home-p'></p>
<p className='home-p'></p> */}

        </div>
           <div className='content-container services-container services-container1'>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Coffee Processing</h3>
                     
                      
                  </article>

                  <article className='service-slides'>
                    <TelecomCarousel></TelecomCarousel>
                 
                  </article>     
            </div>

            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Coffee Export</h3>
                    {/* <p>The following are our coffee types for export:</p>
                    <ul>
                      <li>Natural Ugandan Robusta Coffee.</li>
                      <li>Natural Ugandan Arabica Coffee.</li>
                      <li>Washed Ugandan Arabica Coffee.</li>
                     </ul> */}
                  </article>

                  <article className='service-slides'>
                    <PowerCarousel></PowerCarousel>
                 
                  </article>     
            </div>

           

            

            
           </div>

    </section>
  )
}

export default Services