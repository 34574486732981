import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel'

const Services = () => {

useEffect(()=>{
document.title = 'Project Management | Design Allied World Engineering Ltd';

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
             
            

            <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Project Management</h3>
                      <ul>
                        <li>Management of housing development.</li>
<li>Development infrastructure upgrade projects.</li>
<li>Management of rural and urban community based labour-intensive projects.</li>
<li>Cost control.</li>
<li>Management information systems single / multi-disciplinary projects.</li>
<li>Project facilitation and community empowerment.</li>

                      </ul>
                  </article>

                  <article className='service-slides'>
                    <MaintCarousel></MaintCarousel>
                 
                  </article>     
            </div>


         
           </div>

    </section>
  )
}

export default Services