import Carousel from 'react-bootstrap/Carousel';

function TelecomCarousel() {
  return (
    <Carousel indicators={false} className='services-slides'>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/27.jpg"
          alt="First slide"
        />

      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/28.jpg"
          alt="Second slide"
        />
        
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/29.jpg"
          alt="Third slide"
        />
       
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/30.jpg"
          alt="Third slide"
        />
      </Carousel.Item>
       <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/31.jpg"
          alt="Third slide"
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/32.jpg"
          alt="Third slide"
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/33.jpg"
          alt="Third slide"
        />
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="./images/34.jpg"
          alt="Third slide"
        />
      </Carousel.Item>

      
      

    </Carousel>
  );
}

export default TelecomCarousel;