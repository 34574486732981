import React, {useEffect} from 'react'

const Clients = () => {
useEffect(()=>{
document.title = 'Certification | RAK ENGINEERING LTD';

},[]);



  return (
    <>
    <section className='section-cover clients-cover' >
          <h2 className="home-h2">Certification</h2>
          <p className='clients-para'><strong>ISO Certification awarded to RAK ENGINEERING LTD by RoyalCert International Registrars.</strong></p>
      <p className='clients-para'>Our management systems are certified by <strong>RoyalCert International Registrars GmbH</strong>, Finanzamt Frankfurt, Germany.</p>
      <p className='clients-para'>We hold the following certifications: ISO 9001 2015 (Quality Management), ISO 14001 2015 (Environmental Management), and ISO 45001 2018 (Occupational Health and Safety).</p> 
       
       
        {/* <div className='content-container clients'>

           <article className='client'>
                      <img src='./images/MTN.jpg' alt='client' className='client-image'></img>
           </article>

          </div>  */}
    </section>
    {/* <section className='section-cover certifications-cover' >
      <div className='all-certs'>
        <img src='images/9001.jpg' className='certification-image'></img>
 <img src='images/14001.jpg' className='certification-image'></img>
 <img src='images/45001.jpg' className='certification-image'></img>
    </div>
    </section> */}
    <section className="cert-cover">
    
        <div>
          <img src='images/9001.jpg' className='certification-image'></img>
        </div>

         <div>
          <img src='images/14001.jpg' className='certification-image'></img>
        </div>

         <div>
          <img src='images/45001.jpg' className='certification-image'></img>
        </div>



    </section>
    </>
  )
}

export default Clients