import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel'

const Services = () => {

useEffect(()=>{
document.title = 'Major Roads & Highways | Design Allied World Engineering Ltd';

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
              <div className='service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Major Roads & Highways</h3>
                     
                      <ul>
                      <li>Material surveys, borrow-pit and quarry investigations.</li>
<li>Assessment of traffic loading and pavement design for flexible and rigid pavement.</li>
<li>Low and high volume access, district, provincial and national roads.</li>
<li>Route selection, planning and geometric design.</li>
<li>Stormwater drainage including side drains, culverts, bridges, cut-off drains, sub-surfacedrainage, etc.</li>
	<li>Road safety audits.</li>
<li>Road Rehabilitation.</li>
<li>Visual condition surveys and pavement management systems.</li>
<li>Planning, design, tender documentation, construction supervision and contract administration for conventional and labour-intensive contracts for roads projects.</li>

                     </ul>
                  </article>

                  <article className='service-slides'>
                    <TelecomCarousel></TelecomCarousel>
                 
                  </article>     
            </div>

           
           </div>

    </section>
  )
}

export default Services