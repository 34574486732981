import React from 'react'
import CarouselFade from '../components/HomeCarousel';
import {Link} from 'react-router-dom';
import GoalsCarousel from '../components/GoalsCarousel';
import {FaFileAlt,FaCubes,FaThumbsUp,FaCheck,FaTools, FaQuoteLeft,FaArrowAltCircleRight} from 'react-icons/fa';
const Home = () => {
  return (
    <>
    <section className='home-slider' >
      <CarouselFade></CarouselFade>
    </section>
    <section className='section-cover bg-cover'>
        <div className='content-container'>
          <h2 className="home-h2">Background</h2>
          <div className='underline'></div>

                 <p className='home-p'><strong>Karaz Coffee Factory Limited</strong> is a company established with the main focus of coffee processing and export. The business started about a decade ago however, it was registered as a private limited company in January 2012 under Uganda Registration Services Bureau (URSB). The company is legally registered and compiling with Ugandan laws, under Uganda Revenue Authority, National Social Security Fund, Uganda National Bureau of Standards, Uganda Coffee Development Authority and others.</p>

  
        </div>

    </section>

    <div className='content-container'>
        <h3 className='title-h3'>Our Sustainability Model</h3>
          <div className='underline underline1'></div>

                 <p className='home-p'>Our strategy is to develop a sustainable business model, which can help the company to maintain good
supply of coffee beans to meet the export demands and which must have also benefits to all the players
within the supply chain. The factory is currently working with 4 cooperatives comprised of 800 farmers
and plans to increase the number of farmers to 2,000 in order to sustainable supply the factory and
partnering with 10 to 15 cooperatives. Strategically expand the coffee catch area from central region of
Luwero, Mukono, Kayunga and Masaka to new areas of Rukungiri, Bushenyi, Kasese, Kitagwenda,
Buhweju, Kamwenge, Mbale and Manafwa.</p>
<p className='home-p'>Our business model focuses on an integrated intercropped coffee production, which will involve
promotion of selected high value crops such as soy beans, beans and groundnuts to sustainable
supplement farmer incomes at household level and ensure income for farmer and the company
throughout the year. This strategy will enable farmers concentrate coffee production as a cash crop as
well as promoting the use of fertilizer in production to boost productivity.</p>
      
       
        </div>
    <section className='home-quote' style={{ backgroundImage: `linear-gradient( rgba(55, 22, 14, 0.793), rgba(55, 22, 14, 0.793)), url("./images/12.jpg")`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundAttachment:'fixed',
    padding:'8rem 0'

    }}>
          <div className='quote-content'>
            <h2 className='quote-h3'>Our Goal</h2>
            <div className='pledge-line'></div>
         <p> <span className='quote-cover'><FaQuoteLeft></FaQuoteLeft></span>Our goal is to become the leading intercroped coffee producer and exporter with strong household managed supply chain in Uganda.</p>
        </div>
    </section>

 
  <section className='section-cover section-cover1'>
       <div className='content-container goals-container'>
           <article className='goals-text'>
              <h2 className='home-h2 home-service-h2 goals-h2'>Our Objectives</h2>
               <div className='underline'></div>
              <div className='goals-list'>
                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>To improve the quality of coffee in Uganda.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>To improve the livelihoods of coffee farmers in the country.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>To improve the agricultural sector that is declining.</p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>To compete in the Great Lakes Region market in the near future. </p>
                     </div>

                     <div className='goal'>
                         <span className='goal-icon'>
                          <FaArrowAltCircleRight></FaArrowAltCircleRight>
                          </span>
                          <p className='goal-description'>To make profits and grow a stronger self-sustaining business.</p>
                     </div>

                </div>
                   

           </article>

           <article className='goals-slides'>
               {/* <GoalsCarousel></GoalsCarousel> */}
               <img src='images/2.jpg' className='flyer'></img>

           </article>




       </div>

  </section>
    
  
    </>
  )
}

export default Home