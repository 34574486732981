import React, {useEffect} from 'react';
import PowerCarousel from '../components/PowerCarousel';
import TelecomCarousel from '../components/TelecomCarousel';
import RoadCarousel from '../components/RoadCarousel';
import SupportCarousel from '../components/SupportCarousel';
import MaintCarousel from '../components/Maintenance';
import EmergencyCarousel from '../components/Emergency';
import BuildingsCarousel from '../components/BuildingsCarousel'

const Services = () => {

useEffect(()=>{
document.title = 'Structural Engineering | Design Allied World Engineering Ltd';

},[]);


  return (
    <section className='section-cover services-cover' >
      {/* <h2 className="home-h2 services-h2">What We Do</h2> */}
           <div className='content-container services-container'>
              
            <div className='service-container odd-service-container'>
                  <article className='service-title'>
                    <h3 className='title-h3 service-h3'>Structural Engineering</h3>
                     <ul>
<li>Multi-storey residential, commercial, institutional and industrial buildings.</li>
<li>Reinforced concrete design.</li>
<li>Structural steel design.</li>
<li>Pre-stressed concrete design.</li>
<li>Composite structural design.</li>
<li>Structural masonry and timber design.</li>
	<li>Bridges and culverts.</li>
<li>Deep foundation design.</li>
<li>Planning, design, tender documentation, construction supervision and contract administration for conventional and labour-intensive contracts for structural and building projects.</li>

                     </ul>
                  </article>

                  <article className='service-slides'>
                    <SupportCarousel></SupportCarousel>
                 
                  </article>     
            </div>

           
           </div>

    </section>
  )
}

export default Services