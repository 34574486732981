import React, {useEffect} from 'react'

const Projects = () => {

useEffect(()=>{
document.title = 'Our Team | Karaz Coffee Factory Limited';

},[]);

  return (
    <section className='section-cover team-cover' >
         <div className='content-container table-container'>
           <h2 className="home-h2 projects-h2">Our Team</h2>
           

        

        <div className='content-container content-container1'>

          
                 <p className='home-p home-p1'>
                <strong> Administration and Operations Staff</strong> 
                  </p>


        </div>
  <table>
    <tr>
      <th>S/No.</th>
      <th>Name</th>
      <th>Position</th>
      <th>Experience (Years)</th>
    </tr>

     

      <tr>
      <td>1.</td>
      <td>Ndebesa Fred</td>
      <td>General Manger</td>
      <td>{new Date().getFullYear() - 1997}
</td>
    </tr>

    <tr>
      <td>2.</td>
      <td>Denis Byempaka</td>
      <td>Operations Manager</td>
      <td>{new Date().getFullYear() - 2007}
</td>
    </tr>

     <tr>
      <td>3.</td>
      <td>Jacinta Aine</td>
      <td>Productions Manager</td>
      <td>{new Date().getFullYear() - 2015}
</td>
    </tr>

    <tr>
      <td>4.</td>
      <td>Godwin Mwebaze</td>
      <td>Transport Manager</td>
      <td>{new Date().getFullYear() - 2013}
</td>
    </tr>

     <tr>
      <td>5.</td>
      <td>Fiona Musiimenta</td>
      <td>Finance</td>
      <td>{new Date().getFullYear() - 2017}
</td>
    </tr>

    <tr>
      <td>6.</td>
      <td>Support Staff (6)</td>
      <td>Operations</td>
      <td>{new Date().getFullYear() - 2017}
</td>
    </tr>
    
  
  
  </table>


         </div>
    </section>
  )
}

export default Projects